import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 654.000000 473.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,473.000000) scale(0.100000,-0.100000)">

<path d="M1382 2728 c-16 -16 -15 -33 3 -48 11 -9 15 -33 15 -85 0 -41 -3 -80
-6 -89 -7 -19 -56 -21 -84 -4 -15 10 -22 10 -32 0 -16 -16 3 -49 24 -43 7 2
31 6 53 9 22 2 44 8 49 12 4 5 8 46 7 93 -1 64 2 87 14 97 26 21 12 70 -20 70
-6 0 -16 -5 -23 -12z m38 -23 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7
15 15 15 8 0 15 -7 15 -15z"/>
<path d="M852 2718 c-27 -27 -6 -68 35 -68 12 0 48 -18 79 -40 37 -26 68 -40
89 -40 17 0 36 -5 43 -12 10 -9 16 -8 29 5 16 16 16 18 1 33 -15 15 -18 15
-38 1 -16 -11 -30 -13 -51 -7 -42 12 -131 80 -125 95 5 14 -23 45 -40 45 -6 0
-15 -5 -22 -12z m38 -28 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20
15 20 8 0 15 -9 15 -20z"/>
<path d="M1021 2692 c-21 -39 24 -67 63 -39 22 16 86 9 93 -11 4 -8 1 -23 -6
-34 -14 -23 -5 -58 15 -58 21 0 29 27 16 53 -6 12 -12 33 -14 47 -3 22 -8 25
-51 28 -30 2 -50 8 -54 18 -7 20 -50 18 -62 -4z m49 -17 c0 -8 -6 -15 -14 -15
-17 0 -28 14 -19 24 12 12 33 6 33 -9z"/>
<path d="M1859 2613 c-1 -21 -1 -136 0 -285 l1 -108 105 0 c110 0 156 11 186
46 40 47 27 126 -25 153 l-27 15 26 23 c32 30 41 66 24 104 -22 55 -60 69
-181 69 -96 0 -108 -2 -109 -17z m232 -49 c23 -21 25 -67 4 -88 -20 -20 -66
-28 -135 -24 l-55 3 -3 54 c-5 84 -4 85 89 78 57 -4 86 -10 100 -23z m18 -178
c26 -24 27 -70 2 -95 -20 -20 -117 -36 -176 -29 -30 3 -30 3 -33 76 l-3 74 95
-4 c75 -2 98 -7 115 -22z"/>
<path d="M2330 2619 c-48 -20 -70 -50 -70 -94 0 -57 31 -86 119 -114 37 -12
76 -30 85 -40 21 -23 20 -64 -2 -84 -27 -25 -127 -23 -170 3 -42 26 -54 25
-50 -2 2 -15 16 -29 38 -40 115 -55 250 -14 250 76 0 72 -22 93 -138 131 -63
20 -82 37 -82 75 0 51 24 56 180 38 35 -4 38 25 3 41 -38 18 -132 23 -163 10z"/>
<path d="M2585 2613 c3 -10 37 -70 76 -134 68 -115 69 -117 69 -188 0 -64 2
-71 20 -71 18 0 20 7 20 66 0 64 3 71 70 188 85 148 88 156 64 156 -17 0 -89
-102 -133 -188 -7 -12 -16 -20 -21 -17 -4 3 -36 50 -70 105 -45 73 -67 100
-82 100 -15 0 -18 -4 -13 -17z"/>
<path d="M3050 2619 c-48 -20 -70 -50 -70 -93 1 -63 21 -81 134 -120 37 -13
67 -31 80 -48 l20 -28 -21 -27 c-17 -24 -28 -28 -81 -31 -46 -2 -68 2 -94 18
-43 26 -60 26 -56 -2 2 -17 17 -29 58 -46 100 -41 209 -12 228 61 18 71 -15
114 -109 142 -78 24 -109 48 -109 85 0 51 24 56 180 38 35 -4 38 25 3 41 -38
18 -132 23 -163 10z"/>
<path d="M3300 2610 c0 -16 8 -19 63 -22 l62 -3 3 -183 c2 -183 2 -183 25
-180 22 3 22 5 24 183 l2 180 65 3 c58 3 66 5 66 22 0 19 -8 20 -155 20 -147
0 -155 -1 -155 -20z"/>
<path d="M3680 2425 l0 -205 140 0 c133 0 140 1 140 20 0 18 -8 20 -117 22
l-118 3 0 70 0 70 103 3 c94 2 102 4 102 22 0 19 -7 20 -105 20 l-106 0 3 68
3 67 110 3 c103 3 133 11 119 34 -3 4 -66 8 -140 8 l-134 0 0 -205z"/>
<path d="M4060 2425 c0 -197 1 -205 20 -205 19 0 20 8 22 168 l3 167 70 -140
c39 -77 74 -143 80 -147 16 -12 29 7 96 141 35 72 67 130 72 131 4 0 7 -72 7
-160 0 -153 1 -160 20 -160 19 0 20 7 20 205 l0 205 -28 0 c-26 0 -33 -10 -97
-141 -39 -77 -73 -143 -77 -145 -4 -3 -40 61 -80 141 -67 134 -75 145 -100
145 l-28 0 0 -205z"/>
<path d="M4644 2616 c-42 -19 -64 -53 -64 -97 0 -48 36 -79 122 -108 102 -35
126 -66 87 -114 -18 -24 -27 -27 -79 -27 -42 0 -69 6 -92 20 -41 25 -58 25
-58 1 0 -31 83 -66 156 -66 72 0 109 21 130 71 28 67 -9 120 -107 149 -34 10
-72 27 -85 38 -28 22 -31 58 -7 82 16 17 41 17 163 3 35 -4 38 25 3 41 -43 20
-133 24 -169 7z"/>
<path d="M4943 2614 c-3 -8 -4 -75 -1 -148 5 -157 17 -188 88 -224 37 -19 56
-23 104 -19 66 6 100 26 131 78 17 29 20 56 24 182 l3 147 -26 0 -26 0 0 -140
c0 -162 -9 -192 -67 -217 -69 -28 -150 1 -174 64 -5 13 -9 85 -9 159 0 128 -1
134 -20 134 -11 0 -23 -7 -27 -16z"/>
<path d="M5400 2425 l0 -205 96 0 c117 0 178 14 204 48 38 47 20 131 -31 152
l-29 12 30 28 c58 54 29 148 -51 164 -18 3 -74 6 -125 6 l-94 0 0 -205z m210
152 c23 -8 38 -21 44 -40 21 -58 -26 -88 -133 -85 l-73 3 -2 63 c0 34 4 64 9
66 21 9 122 4 155 -7z m30 -182 c56 -29 48 -94 -15 -121 -19 -8 -66 -14 -105
-14 l-70 0 0 75 0 75 80 0 c53 0 91 -5 110 -15z"/>
<path d="M1066 2514 c-12 -12 -16 -37 -16 -105 0 -124 4 -129 93 -129 44 0 78
5 85 12 16 16 16 210 0 226 -18 18 -143 16 -162 -4z"/>
<path d="M852 2508 c-33 -33 13 -85 47 -54 21 19 72 21 89 4 16 -16 45 12 36
35 -7 19 -29 23 -39 7 -10 -16 -72 -12 -79 5 -7 18 -37 20 -54 3z m43 -28 c0
-22 -29 -18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z"/>
<path d="M894 2426 c-16 -42 28 -75 57 -43 10 10 18 11 30 4 22 -14 51 -1 47
20 -2 13 -13 18 -35 18 -17 0 -35 3 -39 8 -13 12 -54 8 -60 -7z m46 -22 c0 -8
-7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z"/>
<path d="M1400 2425 c-15 -18 -63 -20 -90 -3 -24 15 -46 -2 -36 -27 4 -13 12
-15 29 -10 51 16 83 17 96 1 19 -23 58 -20 66 4 13 41 -38 67 -65 35z m50 -20
c0 -8 -9 -15 -20 -15 -20 0 -26 11 -13 23 12 13 33 7 33 -8z"/>
<path d="M985 2350 c-3 -5 -25 -10 -49 -10 -54 0 -66 -13 -66 -71 0 -29 -6
-51 -15 -59 -35 -29 -8 -79 36 -65 20 7 26 49 9 60 -5 3 -10 31 -10 61 l0 54
43 0 c24 0 48 -5 55 -12 19 -19 50 15 32 36 -14 18 -26 20 -35 6z m-95 -176
c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z"/>
<path d="M1274 2345 c-10 -25 4 -48 24 -39 35 15 94 17 103 3 15 -24 11 -96
-6 -103 -35 -13 -6 -71 32 -64 22 4 29 33 15 62 -6 11 -13 43 -16 70 -7 57
-16 66 -68 66 -20 0 -40 5 -43 10 -9 15 -35 12 -41 -5z m161 -166 c0 -7 -8
-15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28 -15z"/>
<path d="M1055 2243 c-68 -6 -85 -12 -85 -32 0 -10 -7 -24 -15 -31 -20 -16
-16 -49 7 -63 34 -22 66 36 34 63 -8 6 -12 18 -9 26 6 17 84 19 100 3 16 -16
43 -3 43 21 0 13 -6 19 -17 18 -10 -1 -36 -3 -58 -5z m-65 -93 c0 -11 -7 -20
-15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z"/>
<path d="M1160 2230 c0 -24 26 -38 43 -21 16 16 77 14 77 -3 0 -7 -7 -19 -15
-26 -32 -27 -6 -78 35 -65 24 8 27 47 5 65 -8 7 -15 21 -15 31 0 23 -4 25 -72
33 -54 7 -58 6 -58 -14z m145 -80 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0
15 5 20 18 18 9 -2 17 -10 17 -18z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
